<template>
<div>
    <div class="header pb-6 pt-5 pt-lg-8 d-flex align-items-center profile-header" style="
        min-height: 150px;
        background-size: cover;
        background-position: center top;
      ">
        <b-container fluid>
            <!-- Mask -->
            <span style="background-color: #8f384d" class="mask opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="d-flex align-items-center"> </b-container>
        </b-container>
    </div>

    <b-container fluid class="mt--6">
        <b-row>
            <b-col xl="12" class="order-xl-1">
                <card v-show="!show_sheet">
                    <b-button @click="onClickSheet(999)">
                        <img :style="'max-width: 100%;max-height: ' + windowHeight / 3 + 'px' " src="@/assets/cover999.webp" />
                    </b-button>
                    <b-button @click="onClickSheet(2001)">
                        <img :style="'max-width: 100%;max-height: ' + windowHeight / 3 + 'px' " src="@/assets/cover2001.webp" />
                    </b-button>
                    <b-button @click="onClickSheet(2003)">
                        <img :style="'max-width: 100%;max-height: ' + windowHeight / 3 + 'px' " src="@/assets/cover2003.webp" />
                    </b-button>
                    <span v-if="$store.state.department == 0">
                        <b-button @click="onClickSheet(2107)" >
                            <img :style="'max-width: 100%;max-height: ' + windowHeight / 3 + 'px' " src="@/assets/cover2107.webp" />
                        </b-button>
                        <b-button @click="onClickSheet(2108)">
                            <img :style="'max-width: 100%;max-height: ' + windowHeight / 3 + 'px' " src="@/assets/cover2108.webp" />
                        </b-button>
                    </span>
                    <span v-if="$store.state.department == 2">
                        <b-button @click="onClickSheet(2046)" >
                            <img :style="'max-width: 100%;max-height: ' + windowHeight / 3 + 'px' " src="@/assets/cover2046.webp" />
                        </b-button>
                        <b-button @click="onClickSheet(2045)">
                            <img :style="'max-width: 100%;max-height: ' + windowHeight / 3 + 'px' " src="@/assets/cover2045.webp" />
                        </b-button>
                    </span>
                    
                </card>
                <card v-show="show_sheet">
                    <b-button @click="show_sheet=false;" size="sm" class="mb-3" variant="primary">กลับ</b-button>
                    <base-input label="เลือกวิชา" class="">
                        <select class="form-control" v-model="$store.state.subject_index">
                            <option v-for="(t, index) in $store.state.subject_names" :key="index" :value="t.value">
                                {{ t.text }}
                            </option>
                        </select>
                    </base-input>

                    <h6 class="heading text-muted mb-4">{{ subject_current }}</h6>

                    <b-row>
                        <b-col>
                            <!-- <vue-gallery-slideshow
                  :images="$store.state.subject_cover"
                  :index="index"
                  @close="index = null"
                ></vue-gallery-slideshow> -->
                            <gallery :images="$store.state.subject_cover" :index="index" @close="index = null"></gallery>
                            <div v-for="(item, number) in $store.state.items" :key="number">
                                <!-- username -->
                                <div v-if="$store.state.username">
                                    <div>
                                        <!-- {{ item.tag }} -->
                                        <b-button @click="modals.new = item.tag;modals.classic = true" style="border-radius: 50%" size="sm" :variant="item.tag?'success':'white'" :ref="'question' + number">{{ item.id + 1 }}.
                                        </b-button>
                                        <div>
                                            <div v-if="number < 5 || check_subject">
                                                <div>
                                                    <img :style="
                              'max-width: 100%;max-height: ' +
                              windowHeight / 1.5 +
                              'px'
                            " :src="
                              $store.state.baseAPI +
                              '/subjects/solve/' +
                              $store.state.subject_index +
                              '/' +
                              (item.id + 1) +
                              '/' +
                              token
                            " @click="onClick(number)" />
                                                </div>

                                            </div>
                                            <div v-else>
                                                <b-button size="sm" variant="primary" class="text-left mb-1" @click="
                              $store.commit('NEXT_PAGE', {
                                page: 'พรีเมี่ยม',
                              })
                            ">เฉลยละเอียดเพิ่มเติม</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- !username -->
                            </div>
                        </b-col>
                    </b-row>

                </card>
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
import moment from "moment";
import VueGallery from "vue-gallery";

export default {
    components: {
        // BaseSwitch,
        // VueGallerySlideshow,
        gallery: VueGallery,
    },
    watch: {
        // "$store.state.topic_index": function (val) {
        //   if (val === null) return;
        //   var arr = this.$store.state.topic[val].list;
        //   this.$store.state.subject_names = [];
        //   for (let i = 0; i < arr.length; i++) {
        //     this.$store.state.subject_names.push({
        //       value: arr[i].index,
        //       text: arr[i].namegroup + " " + arr[i].subject,
        //     });
        //   }
        //   this.$store.state.subject_index = arr[0].index;
        // },
        // "$store.state.subject_index": function (val) {
        //   this.reload();
        // },
    },
    mounted: function () {
        // this.$store.state.topic_index = null;
        // this.$nextTick(function () {
        //   this.$store.state.topic_index = 0;

        // });
        this.addOnSelected = this.userPackage.subject.slice();
    },
    computed: {
        check_subject() {
            const arr_list = ["991", "992", "993", "994", "995", "996", "997", "998", "999"]
            if (arr_list.includes(this.$store.state.subject_index)) {
                return this.addOnSelected.includes(999)
            }
            return this.addOnSelected.includes(parseInt(this.$store.state.subject_index))
        },
        token() {
            return localStorage.token;
        },
        userPackage() {
            return this.$store.state.userPackage;
        },
        subject_index() {
            return this.$store.state.subject_index;
        },
        user_exp() {
            return moment(this.userPackage.exp).utc().format("DD/MM/YYYY HH:mm:ss");
        },
        date_remain() {
            return moment(this.userPackage.exp).diff(moment(), "days");
        },
        packageDisable() {
            return this.userPackage.exp ?
                moment(this.userPackage.exp).isAfter(moment()) :
                false;
        },
    },
    data() {
        return {
            show_sheet: false,
            visible: false,
            index_solve: 0, // default: 0
            imgs: [],
            addOnSelected: [],
            windowHeight: 0,
            index: -1,
            subject_current: "",

            number_exam: 10,
            modals: {
                notice: false
            },
            randomQ: false,
            solve: false,
            solve1: false,
            perPage: 1000,
            currentPage: 1,
            fields: ["question"],
            items: [],
            //   click: 0,

            form: {
                email: "",
                name: "",
                addition: "",
                receive: true,
                star: 3,
            },
            info: {
                city: "",
                country: "",
                ip: "",
                loc: "",
                org: "",
                postal: "",
                region: "",
                timezone: "",
                mobile: "",
                userAgent: "",
                vendor: "",
                opera: "",
                web: "",
            },
            msgtype: "danger",
            msgAlert: "",
            dismissSecs: 5,
            dismissCountDown: 0,

            bgColor: "#778899",
            successColor: "green",
            position: "buttom-left",
            position2: "top-left",
            toggleWhenAway: false,
            fixedTooltip: true,
            fabActions: [{
                    name: "lightbulb",
                    icon: "lightbulb",
                    color: "#778899",
                },
                {
                    name: "compress",
                    icon: "compress",
                    color: "#778899",
                },
                {
                    name: "shuffle",
                    icon: "shuffle",
                    color: "#778899",
                },
                {
                    name: "autorenew",
                    icon: "autorenew",
                    color: "#778899",
                },
            ],
        };
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowHeight = window.innerHeight - 145;
        },
        onClickSheet(sheetIndex) {
            this.$nextTick(function () {
                var arr = [];
                if (sheetIndex == 999) {
                    arr = [{
                            "namegroup": "",
                            "subject": "พระราชบัญญัติวิศวกร ข้อบังคับ ระเบียบและแนวทางการประกอบวิชาชีพ",
                            "index": 991,
                            "test": 25
                        },
                        {
                            "namegroup": "",
                            "subject": "จรรยาบรรณในการประกอบวิชาชีพ",
                            "index": 992,
                            "test": 25
                        },
                        {
                            "namegroup": "",
                            "subject": "กฏหมายเกี่ยวข้องกับวิชาชีพวิศวกร",
                            "index": 993,
                            "test": 25
                        },
                        {
                            "namegroup": "",
                            "subject": "สิ่งแวดล้อมสำหรับวิศวกร",
                            "index": 994,
                            "test": 25
                        },
                        {
                            "namegroup": "",
                            "subject": "ความปลอดภัยในงานวิศวกร",
                            "index": 995,
                            "test": 25
                        },

                    ];
                } else if (sheetIndex == 2046) {
                    arr = [{
                        "namegroup": "",
                        "subject": "ชีทสรุป Electrical System Design",
                        "index": 2046,
                        "test": 25
                    }];
                } else if (sheetIndex == 2001) {
                    arr = [{
                        "namegroup": "",
                        "subject": "ชีทสรุป Engineering Materials",
                        "index": 2001,
                        "test": 25
                    }];
                } else if (sheetIndex == 2003) {
                    arr = [{
                        "namegroup": "",
                        "subject": "ชีทสรุป Computer Programming",
                        "index": 2003,
                        "test": 25
                    }];
                } else if (sheetIndex == 2107) {
                    arr = [{
                        "namegroup": "",
                        "subject": "ชีทสรุป Theory of Structures",
                        "index": 2107,
                        "test": 25
                    }];
                } else if (sheetIndex == 2108) {
                    arr = [{
                        "namegroup": "",
                        "subject": "ชีทสรุป Structural Analysis",
                        "index": 2108,
                        "test": 25
                    }];
                } else if (sheetIndex == 2045) {
                    arr = [{
                        "namegroup": "",
                        "subject": "ชีทสรุป Electrical Machine",
                        "index": 2045,
                        "test": 25
                    }];
                }
                this.$store.state.subject_names = [];
                var temp = [];
                for (let i = 0; i < arr.length; i++) {
                    temp.push({
                        value: arr[i].index,
                        text: arr[i].namegroup + " " + arr[i].subject,
                    });
                }
                this.$store.state.subject_names = temp;
                this.$store.state.subject_index = arr[0].index;
            });
            this.show_sheet = true;
        },
        onClick(i) {
            this.index = i;
        },
        // onActivate(target) {
        //   console.log(1);
        //   console.log("Receved Event: scrollspy::activate for target ", target);
        // },
        // showModal() {
        //   this.modals.notice = true;
        //   //   this.$refs["modal-2"].show();
        // },
        // lightbulb() {
        //   this.solve = !this.solve;
        //   if (this.solve == false) this.solve1 = false;
        //   this.changeColor();
        // },
        // compress() {
        //   this.solve1 = !this.solve1;
        //   this.solve = this.solve1;
        //   this.changeColor();
        // },
        // shuffle() {
        //   this.randomQ = !this.randomQ;
        //   this.shuffle_check();
        //   this.autorenew();
        //   this.changeColor();
        // },
        // autorenew() {
        //   this.reload();
        // },
        // changeColor() {
        //   if (this.solve) this.fabActions[0].color = this.successColor;
        //   else this.fabActions[0].color = this.bgColor;
        //   if (this.solve1) this.fabActions[1].color = this.successColor;
        //   else this.fabActions[1].color = this.bgColor;
        //   if (this.randomQ) this.fabActions[2].color = this.successColor;
        //   else this.fabActions[2].color = this.bgColor;
        // },
        // countDownChanged(dismissCountDown) {
        //   this.dismissCountDown = dismissCountDown;
        // },
        // showAlert(msg, type) {
        //   this.msgtype = type == null ? "danger" : type;
        //   this.msgAlert = msg;
        //   this.dismissCountDown = this.dismissSecs;
        // },
        // sendInfo() {
        //   //   var e = this;
        //   //   this.axios
        //   //     .post(this.$store.state.api + "php/api.php?tb=tb_info", e.info)
        //   //     .then((response) => {
        //   //       //   console.log(response.data);
        //   //     });
        // },
        // isMobile() {
        //   var check = "0";
        //   (function (a) {
        //     if (
        //       /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        //         a
        //       ) ||
        //       /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        //         a.substr(0, 4)
        //       )
        //     )
        //       check = "1";
        //   })(navigator.userAgent || navigator.vendor || window.opera);
        //   return check;
        // },
        // onSubmit(event) {
        //   //   event.preventDefault();
        //   //   var e = this;
        //   //   this.axios
        //   //     .post(this.$store.state.api + "php/api.php?tb=tb_fund", e.form)
        //   //     .then((response) => {
        //   //       console.log(response.data);
        //   //       e.$refs["modal-1"].hide();
        //   //       e.showAlert(
        //   //         "ขอบคุณสำหรับการสนับสนุน ระบบกำลังไปยัง Facebook Page...",
        //   //         "success"
        //   //       );
        //   //       setTimeout(function () {
        //   //         window.location.replace("https://www.facebook.com/EnGeniusTH");
        //   //       }, 5000);
        //   //     });
        // },
        // scrollToTop() {
        //   window.scrollTo(0, 0);
        // },
        // fetchData(index) {
        //   if (index) {
        //     var data1 = this.$store.state["data" + index][0];
        //     this.pushdata(data1);
        //     this.scrollToTop();
        //     this.subject_current = data1.subject;
        //     // this.axios
        //     //   .get(this.$store.state.api + "detail/data" + index + ".json")
        //     //   .then((response) => {
        //     //     this.pushdata(response.data[0]);
        //     //     this.scrollToTop();
        //     //     e.subject_current = response.data[0].subject;
        //     //   });
        //   }
        // },
        // reload() {
        //   this.fetchData(this.$store.state.subject_index);
        //   this.$store.state.currect = 0;
        //   this.$store.state.click = 0;
        // },
        // pushdata(d) {
        //   this.items = [];
        //   var len = d.ans.length;
        //   //   for (let i = 0; i < len; i++) {
        //   for (let i = 0; i < len; i++) {
        //     if (i == this.number_exam / 2) {
        //       i = len - this.number_exam / 2;
        //     }
        //     this.items.push({
        //       id: i,
        //       question: d.question[i],
        //       ans: d.ans[i],
        //       choice: d.choice[i],
        //       done: -1,
        //     });
        //   }
        //   this.shuffle_check();
        // },
        // shuffle_check() {
        //   this.$store.state.currect = 0;
        //   this.$store.state.click = 0;
        //   for (let i = 0; i < this.info.length; i++) {
        //     this.info[i].done = -1;
        //     this.info[i].done = -1;
        //   }
        //   if (this.randomQ) {
        //     this.randomList(this.items);
        //   } else this.sortList(this.items);
        // },
        // randomList: function (rand) {
        //   return rand.sort(function () {
        //     return 0.5 - Math.random();
        //   });
        // },
        // sortList: function (rand) {
        //   return rand.sort(function (a, b) {
        //     return a.id - b.id;
        //   });
        // },
        // showresult(number, done) {
        //   this.items[number].done = done + 1;
        //   if (this.items[number].done == this.items[number].ans)
        //     this.$store.state.currect++;
        //   this.$store.state.click++;
        // },
    },
};
</script>

<style>
</style>
